@import 'colors.scss';

.container {
  text-align: center;
}

.text {
  display: inline-block;
  position: relative;
  color: #2a3135;
  font-family: Averta;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}

%barPositions {
  content: '';
  position: absolute;
  top: 50%;
  width: 115px;
  height: 1px;
  background-color: $border;
}

.text::before {
  @extend %barPositions;

  right: 100%;
  margin-right: 8px;
}

.text::after {
  @extend %barPositions;

  left: 100%;
  margin-left: 8px;
}
