@import 'breakpoints.scss';

.button {
  margin-bottom: 20px;

  @include from(3) {
    width: 240px;
  }

  @include to(2) {
    width: 270px;
  }
}

.ctaSubtext {
  font-style: italic;

  @include from(3) {
    margin: 0;
  }

  @include to(2) {
    margin: 0 0 35px;
  }
}
