@import 'breakpoints.scss';
@import 'colors.scss';

// stylelint-disable selector-class-pattern

[data-braintree-id='wrapper'] {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

[data-braintree-id='choose-a-way-to-pay'] {
  display: none;
}

[data-braintree-id='other-ways-to-pay'] {
  color: $grey600;
  text-align: center;
}

[data-braintree-id='toggle'] {
  margin-bottom: 20px;
}

:global .braintree-placeholder {
  display: none;
}

:global .braintree-sheet__header .braintree-sheet__header-label {
  @include to(1) {
    display: none;
  }
}
