@import 'colors.scss';

.category {
  min-width: 187px;
  padding: 0 19px 19px 0;
}

.categoryName {
  padding-bottom: 9px;
  font-size: 17px;
  font-weight: 600;
}

.items {
  margin-top: 12px;
  margin-left: 0;
  padding-left: 0;
  list-style: none;

  > li {
    margin-top: 12px;
  }

  a,
  a:visited {
    color: $white;
  }

  p {
    margin: 0;
  }
}
