.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 28px; // provide space under cart heading
}

.cta {
  align-self: center;
  width: 300px;
  margin: 0 0 29px;
}
