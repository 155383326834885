@import 'breakpoints.scss';
@import 'colors.scss';

.underlay {
  display: flex;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 0.3s linear;
  background-color: transparentize($black, 1);
  -webkit-overflow-scrolling: touch; // stylelint-disable property-no-vendor-prefix

  &.mounted {
    background-color: transparentize($black, 0.5);
  }
}

.modal {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  padding: 30px 25px;
  transform: scale(1.1) translateY(-10px);
  transition: all 0.3s linear;
  border-radius: 15px;
  outline: none;
  opacity: 0;
  background-color: $white;

  &.mounted {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
