@import 'breakpoints.scss';
@import 'colors.scss';

.emailContainer {
  margin-bottom: 1em;
}

.passwordForm {
  width: 100%;
  max-width: 500px;
}

.passwordRules {
  text-align: center;
}

.passwordHeader {
  margin-bottom: 32px;
  color: $grey850;
  text-align: left;
}
