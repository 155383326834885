@import 'colors.scss';

.error {
  width: 100%;
  padding: 0 13px 14px;
  color: lighten($error, 20%);
  font-size: 13px;
  line-height: 22px;
  text-align: left;
}
