@import 'sizes.scss';

.container {
  height: 100%;
  padding: 60px 30px;
  text-align: center;
}

.message {
  font-size: 22px;

  &:first-of-type {
    margin-bottom: 0;
  }

  &:nth-of-type(2) {
    margin-top: 0;
  }
}

.details {
  padding: 0 20px;
}

.meta {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 30px auto;
  text-align: left;
}
