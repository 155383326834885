@import 'colors.scss';
// sass-lint:disable no-important

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 1.5em;
  background-color: $black !important;

  &:hover {
    background-color: $grey300 !important;
  }
}

.logo {
  width: 21px;
  margin: 3px 11px 0 0;
}

.buttonText {
  font-family: 'SF Pro Text';
}
