.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  padding: 0.5em 30px;
}

.logo {
  width: 1.6em;
  margin-right: 0.5em;
}
