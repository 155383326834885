@import 'colors.scss';

.link {
  transition: color 0.25s ease-out;
  text-decoration: none;
  cursor: pointer;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    color: map-get($themeColors, 'secondary');

    &:hover {
      color: map-get($themeColors, 'hover');
    }
  }
}

.membership {
  color: $membership;

  &:hover {
    color: $membershipLight;
  }
}

@each $subTheme in kickstarter, transformer {
  .#{$subTheme} {
    color: themeColor('dietbet', $subTheme);

    &:hover {
      color: lighten(themeColor('dietbet', $subTheme), 30%);
    }
  }
}
