@import 'breakpoints.scss';
@import 'colors.scss';

.callout {
  margin: 0 0 17px;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  text-align: center;

  @include from(3) {
    display: none;
  }

  &.memberOnly {
    color: $membership;
  }

  &.runbet {
    color: themeColor('runbet', 'secondary');
  }

  &.dietbetTransformer {
    color: themeColor('dietbet', 'transformer');
  }

  &.dietbetKickstarter {
    color: themeColor('dietbet', 'kickstarter');
  }
}
