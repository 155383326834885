.text {
  font-size: 13px;
  line-height: 15px;
}

.legalDot {
  margin: 0 0.25em;
}

.legalLink {
  &:last-child {
    .legalDot {
      display: none;
    }
  }
}
