@import 'colors.scss';

.image {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: cover;
  object-position: center top;
}

.perks {
  margin-top: -5px;
  padding: 1rem;
  background-color: $membership;
  color: $white;
  text-align: center;

  > div:first-child {
    margin-bottom: 1rem;
  }
}

.perksWayBetter {
  background-color: themeColor('waybetter', 'primary');
}

.perk {
  margin-bottom: 1rem;
  font-weight: 600;
}

.stats {
  margin-bottom: 1rem;
  background-color: $white;
}

.stat {
  display: flex;
  padding: 8px;
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: $grey75;

  &:last-of-type {
    border-bottom: 1px solid $grey75;
  }
}

.label {
  flex: 1;
}

.value {
  font-weight: 600;
}

.support {
  text-align: center;
}

.supportEmail {
  margin-bottom: 1rem;
}
