@import 'breakpoints.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @include to(1) {
    flex-direction: column;
    align-items: center;
  }

  @include from(2) {
    flex-direction: row;
  }
}
