@import 'colors.scss';

.title {
  font-weight: normal;
}

.form {
  display: flex;
  width: 100%;
  height: 100%;
}

.input:first-of-type:last-of-type {
  display: flex;
  flex: 1 1 66%;
  width: inherit;
  border-right: 0;
  border-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-align: left;
}

.inputInner {
  padding: 0.3333em 0.6667em;
}

.button.button {
  flex: 1 0 33%;
  padding: 0.3333em 1em;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
}
