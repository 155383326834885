@import 'breakpoints.scss';
@import 'colors.scss';

.profile {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.message {
  margin-right: 20px;
  color: $grey850;

  @include to(1) {
    display: none;
  }
}

.avatar {
  width: 40px;
  height: 40px;
}

.arrow {
  margin-left: 10px;

  @include to(1) {
    display: none;
  }
}

.menu {
  position: absolute;
  z-index: 5;

  @include from(2) {
    top: calc(100% + 12px);
    right: 0;
  }

  @include to(1) {
    top: var(--header-height-responsive, $headerHeightResponsive);
  }
}
