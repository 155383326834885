@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  user-select: none;
}
