@import 'colors.scss';

.guarantee {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');

  .#{$theme}.guarantee {
    color: darken($secondary, 20%);
  }
}

.kickstarter.guarantee {
  color: themeColor('dietbet', 'kickstarter');
}

.transformer.guarantee {
  color: themeColor('dietbet', 'transformer');
}

.customSingleRound.guarantee {
  color: themeColor('dietbet', 'kickstarter');
}
