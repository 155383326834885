@import 'colors.scss';
// sass-lint:disable no-important

.button {
  width: 100%;
  height: 50px;
  padding: 0;
  border: 1px solid $grey200;
  border-radius: 2em;
  background-color: $white !important;

  &:hover {
    background-color: $grey200 !important;
  }
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.buttonText {
  margin-left: 0.4em;
  color: $grey700;
  font-size: 100%;
  line-height: 1em;
}
