@import 'colors.scss';

.container {
  width: 100%;
}

.sized {
  width: 128px;
  height: 128px;
}

.fileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 8px;
  overflow: hidden;
  border: solid 2px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    border-color: map-get($themeColors, 'secondary');
    background-color: map-get($themeColors, 'background');
    color: map-get($themeColors, 'secondary');
  }
}
