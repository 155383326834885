@import 'colors.scss';

@each $theme, $themeColors in $themes {
  .#{$theme} {
    stroke: map-get($themeColors, 'primary');
    fill: map-get($themeColors, 'primary');
  }
}

.text {
  fill: $white;
  font-family: Averta;
  font-size: 11px;
}
