@import 'breakpoints.scss';
@import 'sizes.scss';

.container {
  --header-height: #{$headerHeight};

  display: flex;
  flex-direction: column;

  @include from(2) {
    min-height: calc(100vh - #{$headerHeight});
  }

  @include to(1) {
    --header-height: #{$headerHeightResponsive};

    min-height: calc(100vh - #{$headerHeightResponsive});
  }
}
