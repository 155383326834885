@import 'colors.scss';

@mixin plainLink {
  color: inherit;
  text-decoration: none;
}

@mixin plainLinks {
  a {
    @include plainLink;
  }
}

@mixin markdownRemoveMargins {
  > p:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }
}

@mixin quotations {
  p::before {
    content: '\201C';
  }

  p::after {
    content: '\201D';
  }
}

@mixin clamp($lines) {
  // https://css-tricks.com/line-clampin/
  // This should be supported across all browsers, including Edge >17. The real
  // CSS solution is still only a draft proposal and the implementation will
  // differ from this widely accepted hack.
  /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
}
