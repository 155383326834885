@import 'colors.scss';
@import 'breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.badge {
  width: 43px;
  margin-bottom: 20px;
}

.starRatings {
  display: flex;
  flex-direction: row;
}

.star {
  width: 24px;
  height: 24px;
  margin: 0 2px;
}

.partialStar {
  overflow: hidden;
}

.storeLink {
  color: $white;
  font-weight: 500;
  text-decoration: underline;

  @include from(3) {
    font-size: 18px;
    line-height: 23px;
  }

  @include to(2) {
    font-size: 16px;
    line-height: 20px;
  }
}
