@import 'colors.scss';

.container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.dot {
  outline: none;
  background: transparent;
  font-size: 50px;
  line-height: unset;
  cursor: pointer;
}

.light .dot {
  color: $white;
}

@each $theme, $themeColors in $themes {
  .#{$theme} .dot {
    color: map-get($themeColors, 'secondary');
  }
}

.inactiveDot {
  opacity: 0.6;
}
