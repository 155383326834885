@import 'breakpoints.scss';
@import 'colors.scss';

$arrowColor: #51555d;

.container {
  overflow: hidden;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  cursor: pointer;

  @include to(2) {
    margin: 20px 0 0;
    padding-bottom: 20px;
  }

  @include from(3) {
    margin: 30px 0 0;
    padding-bottom: 30px;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow.arrow {
  width: 7.7647px;
  height: 12px;
  margin: 0 14px 0 4px;
}

.arrowPath {
  fill: $arrowColor;
}

.heading {
  margin: 0;

  @include from(3) {
    font-size: 17px;
  }
}

.itemContainer {
  box-sizing: content-box;
  transition:
    height 200ms ease-in-out,
    padding 200ms ease-out;
}

@each $theme, $themeColors in $themes {
  .#{$theme}.container {
    border-color: map-get($themeColors, 'primary');
  }
}

.default.container {
  border-color: $fontColor;
}
