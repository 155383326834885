@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: auto;
  padding: 0 12px;
}

.header {
  margin: 24px 0 20px;
  text-align: center;
}

.subHeader {
  margin-bottom: 36px;
  text-align: center;
}

.inputContainer {
  margin-bottom: 24px;
}

.help {
  margin-top: 12px;
  color: $grey700;
  font-size: 14px;
}

.image {
  padding: 24px;
  background-color: $white;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
