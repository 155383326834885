@import 'utils.scss';
@import 'colors.scss';

$borderWidth: 2px;

.container {
  display: inline-block;
  padding: 0.7em 1.875em;
  transition: all 0.25s ease-out;
  border-width: 0;
  border-style: solid;
  border-radius: 2em;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  cursor: pointer;
}

.secondary {
  font-size: 14px;
}

.primary {
  font-size: 18px;
}

.primary,
.secondary {
  &.border {
    padding: calc(0.7em - #{$borderWidth}) calc(1.875em - #{$borderWidth});
  }
}

.dark {
  @include plainLink;

  border-color: $fontColor;
  color: $fontColor;

  &:hover {
    background-color: $fontColor;
    color: $fontColorLight;
  }
}

.border {
  border-width: $borderWidth;
}

.disabled {
  cursor: default;
}

.transparent {
  @include plainLink;

  border-color: $fontColor;
  background-color: transparent;
  color: $fontColor;

  &:hover {
    background-color: $fontColor;
    color: $fontColorLight;
  }

  &.disabled,
  &.disabled:hover {
    border-color: $grey100;
    background-color: transparent;
    color: $grey100;
  }
}

.transparentDark {
  @include plainLink;

  border-color: $fontColorLight;
  background-color: transparent;
  color: $fontColorLight;

  &:hover {
    background-color: $fontColorLight;
    color: $fontColor;
  }

  &.disabled,
  &.disabled:hover {
    border-color: $grey100;
    background-color: transparent;
    color: #525157;
  }
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    border-color: $fontColor;
    background-color: map-get($themeColors, 'secondary');
    color: map-get($themeColors, 'fontColor');

    &:hover {
      background-color: map-get($themeColors, 'hover');
      color: map-get($themeColors, 'fontColor');
    }

    &.disabled,
    &.disabled:hover {
      background-color: $grey100;
      color: #525157;
    }
  }
}

.kickstarter {
  border-color: $fontColor;
  background-color: themeColor('dietbet', 'kickstarter');
  color: $white;

  &:hover {
    background-color: lighten(themeColor('dietbet', 'kickstarter'), 30%);
    color: $white;
  }

  &.disabled,
  &.disabled:hover {
    background-color: $grey100;
    color: #525157;
  }
}

.transformer {
  border-color: $fontColor;
  background-color: themeColor('dietbet', 'transformer');
  color: $white;

  &:hover {
    background-color: lighten(themeColor('dietbet', 'transformer'), 30%);
    color: $white;
  }

  &.disabled,
  &.disabled:hover {
    background-color: $grey100;
    color: #525157;
  }
}

.membership {
  border-color: $fontColor;
  background-color: $membership;
  color: $white;

  &:hover {
    background-color: $membershipLight;
    color: $white;
  }

  &.disabled,
  &.disabled:hover {
    background-color: $grey100;
    color: #525157;
  }
}

.coral {
  background-color: $coral;
  color: $fontColorLight;
}

.coralLight {
  background-color: $coralLight;
  color: $fontColorLight;
}

@each $theme, $themeColors in $themes {
  .inverted.#{$theme} {
    border-color: map-get($themeColors, 'secondary');
    background-color: $white;
    color: map-get($themeColors, 'secondary');

    &:hover {
      background-color: map-get($themeColors, 'secondary');
      color: map-get($themeColors, 'fontColor');
    }

    &.disabled,
    &.disabled:hover {
      border-color: $grey100;
      background-color: $white;
      color: $grey100;
    }
  }

  .complement.#{$theme} {
    border-width: $borderWidth;
    border-color: $white;
    background-color: $white;
    color: map-get($themeColors, 'secondary');

    &:hover {
      border-color: $white;
      background-color: map-get($themeColors, 'secondary');
      color: map-get($themeColors, 'fontColor');
    }

    &.disabled,
    &.disabled:hover {
      border-color: $white;
      opacity: 0.5;
      background-color: $white;
      color: map-get($themeColors, 'secondary');
    }
  }
}
