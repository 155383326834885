@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.children {
  flex: 1 1;
  margin-right: 12px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include from(3) {
    display: none;
  }
}

.icon {
  width: auto;
  height: 12px;
}
