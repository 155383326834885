.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/*
 * When inside of a "display: flex" column layout, the BraintripDropIn's inputs
 * will shrink when using "align-items: center".
 *
 * Previously, this seemed to not be an issue, because the BraintreeDropIn
 * styles were setting the custom [data-braintree-id='wrapper'] width
 * to 100vw. This seems to have worked as long as the cart was as wide as
 * the screen, which is no longer the case with the new personalized carts.
 *
 * One workaround is to wrap it in a display: block div and
 * set its width to 100%.
 */
.dropInContainer {
  display: block;
  width: 100%;
}

.savePayment {
  margin-bottom: 30px;
}

.savePaymentLabel {
  margin-left: 0.5em;
}
