@import 'breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include from(3) {
    padding: 75px 0 41px;
  }

  @include to(2) {
    padding: 60px 0 40px;
  }
}
