@import 'breakpoints.scss';

.text {
  font-size: 13px;
  line-height: 15px;

  @include at(1) {
    text-align: center;
  }
}
