.title {
  text-align: center;
}

.actions {
  margin: 20px 0 0;
}

.close.close {
  display: block;
  width: 80%;
  margin: 0 auto;

  &:focus {
    outline: none;
  }
}
