.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo {
  width: 1.5em;
  margin-right: 1em;
}
