@import 'breakpoints.scss';

$buttonHeight: 50px;
$buttonWidth: 300px;

.join.join {
  margin-right: auto;
  margin-left: auto;

  @include from(3) {
    width: $buttonWidth;
    height: $buttonHeight;
    padding-top: 0;
    padding-bottom: 0;
    line-height: $buttonHeight;
  }

  @include to(2) {
    width: 80%;
  }
}
