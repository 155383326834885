@import 'colors.scss';

.value {
  font-size: 16px;
  font-weight: 600;
}

.name {
  font-size: 14px;
}

.subLabel {
  font-size: 11px;
}

.dark.stat {
  color: $fontColor;
}

.light.stat {
  color: $fontColorLight;
}

@each $theme, $themeColors in $themes {
  .#{$theme}.stat {
    color: map-get($themeColors, 'secondary');
  }
}

@each $subtype in 'kickstarter', 'transformer' {
  $color: themeColor('dietbet', $subtype);

  .#{$subtype}.stat {
    color: $color;
  }
}
