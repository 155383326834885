@import 'colors.scss';

.section {
  width: 100%;
  margin-top: 12px;
  padding: 20px;
  border-radius: 10px;
  background-color: $white;
}

.heading {
  margin-top: 4px;
  margin-bottom: 24px;
  text-align: center;
}

.pressLogoHeading {
  color: $grey900;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.4px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cta {
  align-self: center;
  width: 300px;
  margin: 15px 0 29px;
}

.secureCheckout {
  margin-top: 12px;
}

.passwordHeading {
  margin-bottom: 20px;
  line-height: 2;
}

.passwordRules {
  text-align: left;
}
