@import 'utils.scss';
@import 'breakpoints.scss';

$animationDuration: 350ms;

@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

.container {
  @include plainLinks;

  display: block;
  z-index: 99;
  overflow: hidden;

  @include to(1) {
    visibility: hidden;
    position: fixed;
    inset: $headerHeightResponsive 0 0 0;
    transition: background-color $animationDuration linear;
    background-color: transparentize($black, 1);

    &.open {
      visibility: visible;
      background-color: transparentize($black, 0.5);
    }

    &.closing {
      visibility: visible;
    }
  }
}

.nav {
  display: flex;

  @include from(2) {
    align-items: stretch;
    justify-content: flex-end;
    height: 100%;
  }

  @include to(1) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    padding: 10px 20px;
    overflow-y: auto;
    animation-name: slide-out;
    animation-duration: $animationDuration;
    animation-timing-function: ease-out;
    background: $grey900;
    color: $grey25;
    animation-fill-mode: both;

    .open & {
      animation-name: slide-in;
      animation-duration: $animationDuration;
      animation-timing-function: ease-out;
    }
  }
}

@mixin menuItemGutters {
  > * {
    display: flex;
    align-items: center;
    margin-right: 25px;
  }

  > :last-child {
    margin-right: 0;
  }
}

%items {
  display: flex;
  flex: 0 0 auto;

  @include to(1) {
    flex-direction: column;

    > * {
      margin: 10px 0;
    }
  }

  @include from(2) {
    @include menuItemGutters;

    align-items: center;
    justify-content: flex-end;
    margin-right: 75px;
  }
}

.primaryNav {
  @extend %items;

  @include from(2) {
    font-weight: 600;
  }

  @include at(2) {
    margin-right: 32px;
  }
}

.secondaryNav {
  @extend %items;

  @include at(2) {
    margin-right: 0;
  }
}

.item {
  display: flex;
  flex-direction: column;

  @include to(1) {
    align-items: flex-end;
    font-size: 24px;
  }

  @include at(2) {
    margin-right: 15.8px;
  }

  @include from(2) {
    align-items: flex-start;

    > div {
      padding: 0 3px;
    }

    &:hover &::after {
      width: 100%;
    }
  }
}

.item::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  margin-top: 5px;
  transition: width 250ms cubic-bezier(0.23, 1, 0.32, 1);
  background: $grey850;
}

.active.item::after {
  width: 100%;
}
