@import 'colors.scss';

.container {
  margin: 6px 0;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonLabel {
  color: $grey800;
  font-weight: 600;
  text-align: center;
}

.selectedText {
  color: $white;
}

.item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 45px;
  border: solid $border;
  border-width: 1px 0 1px 1px;
  background-color: $white;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-right-width: 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.disabled {
  background-color: $grey500;
}

.label {
  margin-bottom: 8px;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    border-color: map-get($themeColors, 'secondary');
    background-color: map-get($themeColors, 'secondary');

    &:hover {
      background-color: map-get($themeColors, 'hover');
    }
  }
}
