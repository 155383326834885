@import 'colors.scss';

.container {
  display: block;
  border: $border solid 1px;
  border-radius: 5px;
  background-color: $white;
}

.input {
  width: 100%;
  margin: 0;
  padding: 14px 13px;
  border: 0;
  background: transparent;
  color: $grey900;
  font-size: 17px;
  line-height: 22px;

  &::placeholder {
    color: $grey200;
  }
}
