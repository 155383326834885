// colors derived from http://waybetterbrand.com/

// greyscale
$white: #ffffff;
$grey25: #f6f7f7;
$grey50: #edeff0;
$grey75: #dfe3e4;
$grey100: #d2d7da;
$grey200: #b5bdc1;
$grey300: #97a2a9;
$grey400: #808e96;
$grey500: #697b84;
$grey600: #5c6c73;
$grey700: #4c585f;
$grey800: #3c464a;
$grey850: #363a43;
$grey900: #2a3135;
$black: #000000;
$aliceblue: #f0f8ff;
$pink: #ffc0cb;
$yellow: #ffff00;

$fontColor: $grey850;
$fontMedium: $grey600;
$fontColorLight: $white;

$border: $grey75;
$membership: #ff9762;
$membershipLight: #ffbd38;
$coral: #e34f4b;
$coralLight: #f7947b;
$purple: #6c60ff;
$green: #3abe8d;
$greenMedium: #13ad75;
$greenMediumOpacity15: #13ad7526;
$magnolia: #f6f1f9;

$ok: #8cc800;
$warning: #f5f9e3;
$warningDark: #ffe10f;
$error: #ff324d;
$unknown: $grey600;

$componentLibraryPallet: (
  black: #000000,
  white: #ffffff,
  // Purples
  purple100: #f4e5ff,
  purple200: #ddc8ec,
  purple300: #eae4f7,
  purple400: #6a1099,
  purple500: #530b78,
  purple600: #330b66,

  // Cool Colors
  coolPurple100: #ecefff,
  coolPurple200: #d3dcff,
  coolPurple300: #97abf1,
  coolPurple400: #8b82ff,
  coolPurple500: #6c60ff,
  coolPurple600: #5946d1,
  coolPurple700: #34258d,

  green100: #effcf5,
  green200: #cde9d9,
  green300: #c9f5dc,
  green400: #67ce94,
  green500: #2c8556,
  green600: #2c676a,

  red100: #ffd6d5,
  red200: #e34f4b,
  red250: #fe4a4b,
  red300: #a7130f,

  peach100: #feeedc,
  peach200: #ffddba,
  orange200: #f38a2a,

  beige: #f6f2f0,
  yellow100: #ffecbd,
  yellow200: #fbc02d,

  // Grays
  gray100: #f4f3f8,
  gray200: #cdccd3,
  gray300: #77757b,
  gray400: #525157,
  gray500: #302f35,
  gray600: #2b2529,
  gray700: #4c585f,
);

$themes: (
  waybetter: (
    primary: #8e4aba,
    secondary: #6a1099,
    background: $grey25,
    hover: #8e4aba,
    fontColor: $fontColorLight,
    medium: #e2cfed,
    light: #ede9f2,
  ),
  waybetter-standard: (
    primary: #8e4aba,
    secondary: #6a1099,
    background: map-get($componentLibraryPallet, 'purple100'),
    hover: #8e4aba,
    fontColor: $fontColorLight,
    medium: #e2cfed,
    light: #ede9f2,
  ),
  dietbet: (
    primary: #05dcd6,
    secondary: #00a19f,
    background: #f2fbfb,
    hover: #05dcd6,
    fontColor: $fontColorLight,
    transformer: #b20e8d,
    kickstarter: #d6450a,
  ),
  stepbet: (
    primary: #36cd6d,
    secondary: #14a65b,
    background: #f4fff9,
    hover: #36cd6d,
    fontColor: $fontColorLight,
  ),
  runbet: (
    primary: #3a6aea,
    secondary: #3759d7,
    background: #f6f9fd,
    hover: #3a6aea,
    fontColor: $fontColorLight,
  ),
  sweatbet: (
    primary: #dcc022,
    secondary: darken(#dcc022, 10%),
    background: $grey25,
    hover: #dcc022,
    fontColor: $fontColorLight,
  ),
  runkeeper: (
    primary: #2dc9d6,
    secondary: #2dc9d6,
    hover: darken(#2dc9d6, 10%),
    fontColor: $fontColorLight,
  ),
  facebook: (
    primary: #1877f2,
    secondary: #1877f2,
    light: #1877f2,
    hover: darken(#1877f2, 10%),
    fontColor: $fontColorLight,
  ),
  landing: (
    primary: #f7947b,
    secondary: #e34f4b,
    background: #fff7f4,
    light: #f7947b,
    hover: darken(#e34f4b, 10%),
    fontColor: $fontColorLight,
  ),
);

@function themeColor($theme, $color) {
  @return map-get(map-get($themes, $theme), $color);
}
