@import 'colors.scss';

.container {
  width: 25px;
}

%theme {
  transition: fill 0.25s ease-in;
}

.dark {
  @extend %theme;

  fill: $grey850;
}

.light {
  @extend %theme;

  fill: $fontColorLight;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    @extend %theme;

    fill: map-get($themeColors, 'secondary');
  }
}
