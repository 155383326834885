@import 'breakpoints.scss';

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.landscapeImage {
  @include to(1) {
    display: none;
  }
}

.portraitImage {
  @include from(2) {
    display: none;
  }
}
