@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.legal {
  @include markdownRemoveMargins;

  max-width: 580px + 50px;
  padding: 0 25px;
  color: $grey200;
  line-height: 16px;
  text-align: center;

  @include from(3) {
    margin: auto auto 60px;
    font-size: 15px;
  }

  @include to(2) {
    margin: auto auto 25px;
    font-size: 13px;
  }
}
