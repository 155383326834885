@import 'breakpoints.scss';
@import 'colors.scss';
@import 'sizes.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  @include from(3) {
    flex-direction: row;
    justify-content: space-evenly;
  }

  @include to(2) {
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    width: auto;
  }
}

.stat {
  margin: 10px 15px;
}

.roundNumber {
  color: $grey600;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.roundDate {
  color: $grey600;
  font-size: 15px;
  line-height: 20px;
}

.inputNumber {
  max-width: 100px;
  margin-top: 10px;
  border: 1px solid $grey100;
  border-radius: 4px;
  background-color: $grey25;
  color: $grey900;
  font-size: 17px;
  line-height: 22px;

  input::placeholder {
    color: $grey500;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
  }

  *:focus {
    outline: none;
  }
}

.initialWeight {
  @include to(2) {
    width: 100%;
  }

  .roundNumber {
    color: themeColor('dietbet', 'secondary');
  }

  .roundDate {
    color: themeColor('dietbet', 'secondary');
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
  }

  .inputNumber {
    input:focus {
      outline: auto;
    }
  }
}

.finalWeight {
  .roundNumber {
    color: themeColor('dietbet', 'secondary');
  }
}
