@import 'colors.scss';

.container {
  display: flex;

  > * {
    margin-right: 23px;

    &:last-child {
      margin-right: 0;
    }
  }
}
