@import 'colors.scss';
@import 'utils.scss';

.oldPlatformLegal {
  a {
    text-decoration: none;
  }
}

@each $theme, $themeColors in $themes {
  .#{$theme}.oldPlatformLegal {
    a {
      color: map-get($themeColors, 'secondary');
    }
  }
}
