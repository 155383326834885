@import 'breakpoints.scss';
@import 'colors.scss';

.modal {
  text-align: center;

  @include from(2) {
    max-width: 575px;
    padding: 65px 60px 20px;
  }

  @include to(1) {
    padding: 35px 30px 20px;
  }
}

.message {
  margin: 35px auto;

  @include from(2) {
    max-width: 60%;
  }
}

.form {
  margin: 0 auto 15px;

  @include from(2) {
    width: 80%;
  }
}

.input {
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 35px;
  }
}

.cta {
  display: block;
  margin: 0 auto;

  @include from(2) {
    width: 65%;
  }

  @include to(1) {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
}

.legal {
  color: $grey200;
  font-size: 13px;
}

@each $theme, $themeColors in $themes {
  .#{$theme} .title {
    color: map-get($themeColors, 'secondary');
  }
}
