@import 'colors.scss';

.header {
  color: $grey500;
  text-align: center;
}

.textContainer {
  margin-bottom: 16px;
  color: $grey500;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  display: block;
  width: 100%;
  margin-bottom: 12px;

  &:focus {
    outline: none;
  }
}

.link {
  display: flex;
}

.arrow {
  height: 12px;
  margin-top: 6px;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    color: map-get($themeColors, 'secondary');
    font-weight: 600;
  }
}
