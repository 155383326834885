.wrapper {
  display: flex;
  align-items: center;
}

.container {
  display: flex;

  /* height: 1em; */
  overflow: hidden;
}

.digit {
  position: relative;
  line-height: 1.4;
}

.digit.number.animate {
  animation: odometer var(--animation-duration, 0.5s) ease-in-out;
  animation-fill-mode: both;
  animation-delay: var(--animation-delay, 0);
}

/* .digit.separator {
} */

.digit.number .value {
  display: inline-block;
  width: 1ch; /* ensures consistent spacing when using a non-monospaced font */
  text-align: center;
}

.digit.number::before {
  content: var(--from);
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  line-height: 1.4;
  text-align: center;
}

@keyframes odometer {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}
