@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.container {
  border: 1px solid $border;
  background-color: $white;

  @include from(3) {
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    border-radius: 5px;
  }

  @include to(2) {
    width: 100%;
    padding: 12px;
    border-right: 0;
    border-left: 0;
  }
}

.stacked {
  @include from(3) {
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-of-type {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  & ~ & {
    border-top: 0;
  }
}
