@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: 100vh;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    background-color: map-get($themeColors, 'background');
  }
}
