@use 'sass:math';

@import 'breakpoints.scss';
@import 'colors.scss';

.containerOuter {
  background-color: $white;

  @include to(2) {
    width: 100%;
    margin-bottom: 30px;
  }

  @include from(3) {
    margin-bottom: 65px;
  }
}

.container {
  display: flex;
  position: relative;

  @include to(2) {
    flex-direction: column;
    align-items: center;
  }

  @include from(3) {
    flex-direction: row;
    padding: 44px 80px 61px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @include from(3) {
    max-width: 552px;
    padding-top: 21px;
  }
}

.image {
  object-fit: cover;

  @include from(3) {
    width: 260px;
    height: 260px;
    margin-right: 24px;
    border-radius: 8px;
  }

  @include to(2) {
    width: 100%;
    min-height: 190px;
    max-height: 350px;
    margin-bottom: 26px;
    background-color: $grey500;
  }
}

.squareImage {
  width: 190px;
  height: 190px;
  margin: 20px 25px;
  border-radius: 5px;
  object-fit: cover;
  object-position: top center;
}

.smallImage {
  @include from(3) {
    display: none;
  }
}

.largeImage {
  @include to(2) {
    display: none;
  }
}

.title {
  @include from(3) {
    margin-bottom: 20px;
    font-size: 48px;
  }

  @include to(2) {
    margin-bottom: 22px;
    padding: 0 30px;
    text-align: center;
  }
}

.description {
  margin: 0 0 22px;
  font-size: 17px;

  @include to(2) {
    display: none;
  }
}

.stats {
  @include from(3) {
    margin-bottom: 22px;
    text-align: left;
  }

  @include to(2) {
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
  }
}

.statValue {
  @include from(3) {
    font-size: 28px;
  }

  @include to(2) {
    font-size: 22px;
  }
}

.statName {
  color: $grey500;

  @include from(3) {
    font-size: 17px;
  }
}

.statPerRound {
  color: $grey500;
}

.host {
  @include from(2) {
    display: none;
  }
}

.range {
  line-height: 19px;

  @include from(3) {
    order: -1;
    margin-bottom: 15px;
    font-size: 17px;
  }

  @include to(2) {
    margin-bottom: 50px;
    text-align: center;
  }
}

.join.join {
  $buttonHeight: 50px;
  $buttonWidth: 300px;

  position: absolute;
  bottom: 0 - (math.div($buttonHeight, 2));

  @include from(3) {
    left: calc(50% - #{math.div($buttonWidth, 2)});
  }

  @include to(2) {
    right: auto;
    left: auto;
  }
}
