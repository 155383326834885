@import 'colors.scss';

%theme {
  transition: fill 0.25s ease-in;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    @extend %theme;

    fill: map-get($themeColors, 'secondary');
  }
}

.membership {
  fill: $membership;
}
