@import 'breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  min-width: 320px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}

.infoContainer {
  display: grid;
  grid-row-gap: 14px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  justify-items: center;
}
