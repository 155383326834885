@import 'breakpoints.scss';
@import 'colors.scss';

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');
  $color: if($theme == 'waybetter', $fontColorLight, $fontColor);

  .#{$theme}.container {
    background-color: lighten($secondary, 20%);
    color: $color;
  }
}

.content {
  max-width: 321px;
  margin: 0 auto;
  text-align: center;

  @include from(3) {
    padding: 20px 0;
  }

  @include to(2) {
    padding: 15px 0;
    font-size: 13px;
  }
}

.fields {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
