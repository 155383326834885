@import 'colors.scss';

/*
 * This labels is currently placed above the BraintreeDropIn
 * component. The underlying (external) component ends up with
 * a large top-margin, so the bottom margin here is excluded,
 * to make up for that.
 */
.container {
  display: grid;
  grid-column-gap: 0.6rem;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  margin: 32px 32px 0;
  color: $grey600;
}

/* stylelint-disable selector-pseudo-class-no-unknown */
:export {
  color: $grey600;
}
