.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  margin-bottom: 20px;
}

.recaptchaInput {
  border: 0;
}
