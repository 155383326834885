@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  height: 184px;
  padding: 25px;
  border: 1px solid $grey75;
  border-radius: 4px;
  background: $white;
  color: $black;
  text-align: center;
}

.image {
  height: 35px;
}

.content {
  display: flex;
  position: relative;
  flex: 0 0 225px;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 0;
}

.description {
  color: $grey900;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}
