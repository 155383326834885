@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.submitButton {
  margin-top: 30px;
}
