@import 'utils.scss';
@import 'breakpoints.scss';
@import 'sizes.scss';
@import 'colors.scss';

.defaultBackground {
  background-color: $white;
}

.header {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0 20px;

  @include to(1) {
    justify-content: center;
    height: $headerHeightResponsive;
  }

  @include from(2) {
    height: $headerHeight;
  }
}

.menuButton {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 0 20px;
  border: 0;
  background: transparent;
  font-size: 30px;
  cursor: pointer;

  @include from(2) {
    display: none;
  }
}

.menuIcon {
  width: 26px;
  height: 20px;
}

.logo {
  transition: all 0.2s ease-in-out;

  @include to(1) {
    height: 22px;
  }

  @include from(2) {
    height: 27px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;

  &:hover {
    .logo {
      transform: scale(1.08);
    }
  }
}

.menu.menu {
  flex: 0 1 100%;

  @include from(2) {
    display: none;

    .corporate & {
      display: block;
    }
  }
}

.product {
  display: flex;

  @include to(1) {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
  }
}

.productLinks {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;

  @include to(1) {
    display: none;
  }

  > * {
    margin-right: 50px;
  }

  .sweatbet &,
  .corporate & {
    display: none;
  }
}

.corporate .login {
  display: none;
}
