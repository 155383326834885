@import 'breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  @include to(1) {
    margin: 0 0 20px;
  }

  @include from(2) {
    flex-direction: column;
    justify-content: center;
    margin: 0 0 30px;
  }
}

.avatar {
  @include to(1) {
    margin-right: 20px;
  }

  @include from(2) {
    margin-bottom: 20px;
  }
}

.name {
  font-weight: 600;
}
