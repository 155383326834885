@import 'sizes.scss';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.full {
  flex: 0 1 $maxWidth;
  max-width: $maxWidth;
}

.narrow {
  flex: 0 1 620px;
  max-width: 620px;
}
