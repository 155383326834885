@import 'colors.scss';

.container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}

.padding {
  padding: 30px 0;
}

.transparent {
  background: transparent;
}

.dark {
  background-color: $grey850;
  color: $fontColorLight;
}

.light {
  background-color: $white;
  color: $fontColor;
}

.waybetterLight {
  background-color: $magnolia;
  color: $grey850;
}

.coral {
  background-color: $coral;
  color: $fontColorLight;
}

.coralLight {
  background-color: $coralLight;
  color: $fontColorLight;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    background-color: map-get($themeColors, 'secondary');
    color: map-get($themeColors, 'fontColor');
  }
}
