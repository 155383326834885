.container {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.image {
  flex: 1 1;
  width: 100%;
  max-width: 110px;
  height: 100%;
  max-height: 110px;
  opacity: 0.5;
}
