@import 'sizes.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button {
  margin-top: $buttonSpacing;
}
