@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include from(3) {
    padding: 75px 0 41px;
  }

  @include to(2) {
    padding: 60px 0 40px;
  }
}

.logoTitle {
  margin-bottom: 15px;
  color: $grey400;
  font-size: 13px;
}

.logo {
  height: 34px;
  margin-bottom: 40px;
}

.linksTitle {
  margin-bottom: 11px;
  color: $grey400;
  font-size: 13px;
  font-weight: normal;
}

.links {
  @include plainLinks;

  margin-bottom: 30px;
  font-size: 19px;
  line-height: 33px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.primaryMenu.primaryMenu {
  margin-bottom: 20px;
  font-weight: 600;
}

.secondaryMenu {
  margin-bottom: 30px;
}
