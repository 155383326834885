@import 'colors.scss';
@import 'breakpoints.scss';

.footer {
  background-color: $grey850;
  color: $white;
}

.container {
  padding: 45px 0;
}

.categoryContainer {
  margin-bottom: 15px;

  @include at(1) {
    text-align: center;
  }

  @include from(2) {
    display: flex;

    div {
      flex: 1 100%;
    }
  }
}
