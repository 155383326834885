@import 'colors.scss';

.container {
  width: 31px;
  transition: transform 0.25s ease-in;
}

.path {
  transition: fill 0.25s ease-in;
}

.up {
  transform: rotate(0.75turn);
}

.right {
  transform: none;
}

.down {
  transform: rotate(0.25turn);
}

.left {
  transform: rotate(0.5turn);
}

.dark {
  fill: $grey850;
}

.light {
  fill: $fontColorLight;
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    fill: map-get($themeColors, 'secondary');
  }
}
