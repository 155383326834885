@import 'colors.scss';

.container {
  display: block;
  border: $border solid 1px;
  background-color: $white;
}

.notStacked {
  border-radius: 5px;
}

.stacked {
  & ~ & {
    border-top: 0;
  }

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.hasError {
  background-color: $warning;
}

.input {
  width: 100%;
  margin: 0;
  padding: 14px 13px;
  border: 0;
  background: transparent;
  color: $grey900;
  font-size: 17px;
  line-height: 22px;

  &::placeholder {
    color: $grey200;
  }
}

.error {
  width: 100%;
  padding: 0 13px 14px;
  color: lighten($error, 20%);
  font-size: 13px;
  line-height: 22px;
  text-align: left;
}

.label {
  margin-bottom: 8px;
}
