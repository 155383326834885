@import 'colors.scss';

.stat {
  display: flex;
  padding: 8px;
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: $grey75;

  &:last-of-type {
    border-bottom: 1px solid $grey75;
  }
}

.label {
  flex: 1;
}

.value {
  font-weight: 600;
}
