@import 'breakpoints.scss';
@import 'colors.scss';

$modalRadius: 15px;
$headingColor: #05a9e2;
$dailyBurnFontColor: #474f5d;
$declineButtonColor: #7f7f8a;
$dailyBurnPrimary: #e8392f;
$dailyBurnSecondary: #e2762a;
$footerBackgroundColor: #f1f1f1;
$footerFontColor: #808080;

@font-face {
  font-family: Circular;
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/CircularStd-Book.otf') format('opentype');
}

@font-face {
  font-family: Circular;
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/CircularStd-Medium.otf') format('opentype');
}

.modal.modal {
  font-family: Circular, Averta;

  @include from(3) {
    position: relative;
    max-width: 960px;
    padding: 0;
  }

  @include to(2) {
    position: absolute;
    top: 32px;
    right: 0;
    left: 0;
    max-width: 100vw;
    padding: 0;
    border-radius: 0;
  }
}

.loadingOverlay {
  visibility: hidden;
  position: absolute;
  width: 100%;
  transition: background-color 0.3s linear;

  @include from(3) {
    height: 100%;
    border-radius: $modalRadius;
  }

  @include to(2) {
    height: calc(100vh - 32px);
  }
}

.loadingOverlay.submitting {
  visibility: visible;
  background-color: transparentize($black, 0.75);
}

.background {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 64%;
  height: 100%;
  border-top-right-radius: $modalRadius;
  object-fit: contain;
  object-position: top;

  @include to(2) {
    display: none;
  }
}

.heading {
  margin: 52px 0 45px 40px;
  color: $headingColor;
  font-size: 32px;

  @include at(2) {
    margin: 52px auto 45px;
    text-align: center;
  }
}

.content {
  display: flex;

  @include from(3) {
    justify-content: space-between;
    padding: 0 20px 60px 40px;
  }

  @include to(2) {
    flex-direction: column;
    padding-bottom: 60px;
  }
}

.contentContent {
  @include to(2) {
    padding: 0 40px;
  }
}

.logo {
  width: 200px;

  @include at(2) {
    display: block;
    margin: 0 auto;
  }
}

.message {
  max-width: 460px;
  color: $dailyBurnFontColor;
  font-size: 18px;
  line-height: 23px;

  @include at(2) {
    margin: 0 auto 20px;
  }

  strong {
    font-weight: 600;
  }
}

.ctaGroup {
  display: flex;
  flex-direction: column;

  @include from(3) {
    width: 275px;
  }

  @include to(2) {
    width: 100%;
    margin-bottom: 30px;
  }
}

%cta {
  font-size: 16px;
  cursor: pointer;
}

.acceptCta {
  @extend %cta;

  margin-bottom: 12px;
  padding: 0.7em 1.875em;
  border-radius: 1.5em;
  background: linear-gradient(0.25turn, $dailyBurnPrimary, $dailyBurnSecondary);
  color: $white;
}

.declineCta {
  $borderWidth: 2px;

  @extend %cta;

  padding: calc(0.7em - #{$borderWidth}) calc(1.875em - #{$borderWidth});
  border: $borderWidth solid $declineButtonColor;
  border-radius: 1.5em;
  background: $white;
  color: $declineButtonColor;
}

.imageBackground {
  width: 100%;

  @include from(3) {
    display: none;
  }
}

.image {
  @include to(2) {
    display: block;
    max-width: 100vw;
    margin: -100px auto 0;
  }
}

.footer {
  padding: 10px 40px;
  background: $footerBackgroundColor;
  color: $footerFontColor;
  font-size: 12px;
  text-align: center;

  @include from(3) {
    border-bottom-right-radius: $modalRadius;
    border-bottom-left-radius: $modalRadius;
  }
}

.link {
  color: $dailyBurnSecondary;

  &:hover {
    color: lighten($dailyBurnSecondary, 10%);
  }
}
