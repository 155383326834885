$baseSize: 16px;

$maxWidth: 1024px;

$headerHeight: 86px;
$headerHeightResponsive: 50px;

$heroBackgroundWidth: $maxWidth * 1.45;

// Forms
$buttonSpacing: 16px;
$buttonFont: 100%;
$dividerSpacing: 30px;
