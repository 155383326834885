@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  overflow: hidden;
  cursor: pointer;
}

.headingContainer {
  display: flex;
  align-items: center;
}

.arrow.arrow {
  width: 7.7647px;
  height: 12px;
  margin: 0 14px 0 4px;
}

.heading {
  margin: 0;
}

.itemContainer {
  box-sizing: content-box;
  margin-left: 26px;
  transition:
    height 200ms ease-in-out,
    padding 200ms ease-out;
}

@each $theme, $themeColors in $themes {
  .#{$theme}.headingContainer {
    color: map-get($themeColors, 'secondary');
  }
}

.default.headingContainer {
  color: $fontColor;
}
