@import 'breakpoints.scss';
@import 'utils.scss';

.badges {
  @include from(3) {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
}

.badge {
  display: block;
  height: 53.35px; // equals ~180px wide
  margin: 0 10px 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.disabled::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    border-radius: 4px;
    background-color: transparentize($black, 0.5);
  }
}

.qrCode {
  width: 100%;
  display: flex;
  justify-content: center;
}
